import * as React from 'react';

import { ContactForm } from '../components/contact-form';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import contactHero from '../images/contact-hero.svg';

function IndexPage(): JSX.Element {
  return (
    <>
      <SEO title="Contact" />
      <Layout>
        <Hero />
        <ContactForm />
      </Layout>
    </>
  );
}

function Hero(): JSX.Element {
  return (
    <div className="relative z-10 mt-24 -mb-16">
      <div className="relative px-4 mt-4 sm:mt-6 lg:mt-8 sm:px-6 lg:px-8">
        <div className="relative block px-4 pt-12 rounded-2xl text-teal sm:px-6 lg:px-8">
          <div className="grid items-center w-full max-w-4xl mx-auto lg:grid-cols-2">
            <div className="mx-auto">
              <h1 className="text-5xl font-black font-display text-pink">
                <span>Contact Us </span>
              </h1>
              <p className="mt-4 text-3xl font-black font-display">
                Lorem ipsum dolor sit amet, consectetur.
              </p>
            </div>
            <img
              src={contactHero}
              height={150}
              width={208}
              alt=""
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
